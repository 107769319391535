import NavBar from "../../components/NavBar/NavBar"
import Navigation from "../../components/Navigation/Navigation"
import styles from "./Stacks.module.css"
import Tooltip from '@mui/material/Tooltip';

const Stacks = () => {

    const groups = [
        {
            name: "Back",
            stacks: [
                {img: "node", name:"Node.js"},
                {img: "mongodb", name:"MongoDB"},
                {img: "prisma", name: "Prisma"},
            ]
        },
        {
            name: "Front",
            stacks: [
                {img: "next", name: "Next.js"},
                {img: "react", name: "React.js"},
                {img: "tailwind", name:"Tailwind CSS"}
            ]
        },
        {
            name: "Devops",
            stacks: [
                {img: "docker", name:"Docker"},
                {img: "git", name:"Git"},
            ]
        },
        {
            name: "Data",
            stacks: [
                {img: "python", name:"Python"},
                {img: "numpy", name:"Numpy"},
                {img: "plt", name:"Matplotlib"},
            ]
        }
        
    ]

    return(
        <div className={styles.content}>
            <img src="/images/grid.jpg" className={styles.bg} alt="Grid box" />
            <NavBar />
            <div className={styles.titles}>
                <div className={styles.top}>
                    <div></div>
                    <h1>MY TECH STACKS</h1>
                </div>
                <p>Here are all my skills, mainly in web stacks</p>
                <div className={styles.stacks}>
                   {groups.map((g, index) => (
                    <div key={index} className={styles.box}>
                        <h2 style={{margin: 0, marginBottom: "20px"}}>{g.name}</h2>
                        <div>
                            {g.stacks.map((stack, index2) => (
                                <Tooltip title={stack.name} arrow key={`${index}_${index2}`} >
                                    <img src={`/stacks/${stack.img}.png`} alt={`Logo ${stack.name}`} className={styles.logo} />
                                </Tooltip>
                            ))}
                        </div>
                    </div>
                   ))}
                </div>
            </div>
            <Navigation previousRoute="/" previousText="about me" nextRoute="/works" nextText="works" />
  

        </div>

    )
}

export default Stacks