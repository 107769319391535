import styles from "./Footer.module.css"

const Footer = () => {
    return(
        <div className={styles.footer}>
            <p>SOFTWARE ENGINEER</p>
            <div>
                <a href="https://github.com/0zzone" target="_blank" rel="noreferrer">GITHUB</a>
                <a href="/contact">CONTACT</a>
                <a href="www.linkedin.com/in/matteobonnet" target="_blank">LINKEDIN</a>
            </div>
        </div>
    )
}

export default Footer