import { useParams } from "react-router-dom"
import {projects, getColorStack} from "../../data/projects"
import styles from "./Project.module.css"
import { useState } from "react"
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const Project = () => {

    const {id} = useParams()
    if(id === undefined || parseInt(id) >= projects.length) window.location.href = "/projects"
    
    const [project, setProjet] = useState<any>(id ? projects[parseInt(id)] : 0)

    return(
        <div className={styles.content}>
            <div className={styles.left}>
                <a className={styles.back} href="/projects">&#x2190; back to projects</a>
                <h1 className={styles.title}>{project.title}</h1>
                <p>{project.description}</p>
                <Stack direction="row" className={styles.stacks}>
                    {project.stacks.map((stack: any, index: number) => (
                        <Chip size="medium" label={stack} variant="outlined" color={getColorStack(stack)} />
                    ))}
                </Stack>
                {project.link && <a target="_blank" rel="noreferrer" className={styles.link} href={project.link}>View website &#x2197;</a>}
            </div>
            <div className={styles.right}>
                {project.image && <img src={project.image} alt={project.title} />}
            </div>
        </div>
    )

}

export default Project