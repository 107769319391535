import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './pages/Home/Home';
import Stacks from './pages/Stacks/Stacks';
import Works from "./pages/Works/Works"
import Projects from './pages/Projects/Projects';
import Project from './pages/Project/Project';
import Contact from './pages/Contact/Contact';


const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/stacks",
    element: <Stacks />
  },{
    path: "/works",
    element: <Works />
  },
  {
    path: "/projects",
    element: <Projects/>
  },
  {
    path: "/project/:id",
    element: <Project />
  },
  {
    path: "/contact",
    element: <Contact />
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
