import NavBar from "../../components/NavBar/NavBar"
import Navigation from "../../components/Navigation/Navigation"
import styles from "./Projects.module.css"
import {projects} from "../../data/projects"

const Projects = () => {

    return(
        <div className={styles.content}>
            <img src="/images/grid.jpg" className={styles.bg} alt="Grid box" />
            <NavBar />
            <div className={styles.titles}>
                <div className={styles.top}>
                    <div></div>
                    <h1>PROJECTS</h1>
                </div>
                <div className={styles.projects}>
                    {projects.map((projet: any, index: number) => (
                        <div key={index} onClick={() => window.location.href = `/project/${index}`}>
                            <div>
                                <h2>{index+1}</h2>
                                <h1>{projet.title}</h1>
                            </div>
                            <p>{projet.description}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Navigation previousRoute="/works" previousText="works" nextRoute="/contact" nextText="contact" />
        </div>
    )
}

export default Projects