export const getColorStack = (stack) => {
    switch(stack){
        case 'Next Js':
            return 'warning'
        case 'React Js':
            return "warning"
        case 'Node Js':
            return 'secondary'
        case 'Prisma':
            return 'success'
        case 'Docker':
            return 'primary'
        default:
            return 'default'
    }
}

export const projects = [
    {
        title: "Code Mapper",
        description: "This app allows you to learn more about data structures by vizualizing them. You also can do some exercises immediatly corrected on.",
        stacks: ['Next Js', 'Node Js', 'Prisma', 'P5.js', 'Docker'],
        link: "https://app.codemapper.fr",
    },
    {
        title: "Menufy",
        description: "It helps restaurants to have their menus online and translate them. Idea for the Olympic Games in Paris 2024.",
        stacks: ['React Js', 'Node Js', 'Docker'],
        link: "https://lrb.matteo-bonnet.fr"
    },
    {
        title: "Taromaster",
        description: "Have you ever played Tarot? It's a very famous french cards game. This app allows you to count points while playing!",
        stacks: ['React Js', 'Node Js', 'Prisma', 'Docker'],
        image: "/projects/taromaster.png"
    },
    {
        title: "Facturatio",
        description: "If you are a facturation.pro user, you can connect to this website to generate amazing invoices.",
        stacks: ['Node Js', 'Docker']
    },
    {
        title: "CryptInfos",
        description: "Are you a trader? This X (Twitter) bot is made for you. You can check daily the amount of 4 crypto currencies.",
        stacks: ['Node Js']
    },
    {
        title: "Reciiipes",
        description: "If you want to share your recipes on the blockchain, it's possible. The Ethereum blockchain is used to store the data.",
        stacks: ['Node Js', 'Solidity']
    },
    {
        title: "Marché des Âmes",
        description: "This virtual web market is directly connected to a discord bot which can manage the orders as well for a Minecraft Server (2.5K+ members)",
        stacks: ['Node Js']
    },
    {
        title: "EcoleDirecte",
        description: "Have you ever wanted to rate your teachers? It's possible here if your institution uses EcoleDirecte website",
        stacks: ['Node Js']
    }
]