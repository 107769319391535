import NavBar from "../../components/NavBar/NavBar"
import Navigation from "../../components/Navigation/Navigation"
import styles from "./Contact.module.css"

const Contact = () => {
    return(
        <div className={styles.content}>
            <img src="/images/grid.jpg" className={styles.bg} alt="Grid box" />
            <NavBar />
            <div className={styles.titles}>
                <p>Keep in touch!</p>
                <h1>contact@matteo-bonnet.fr</h1>
                <div>
                    <a href="https://github.com/0zzone" target="_blank" rel="noreferrer">GITHUB</a>
                    <a href="https://www.linkedin.com/in/matteobonnet" target="_blank" rel="noreferrer">LINKEDIN</a>
                </div>
            </div>
            <Navigation previousRoute="/projects" previousText="projects" />
        </div>
    )
}

export default Contact