import { useState } from "react"
import styles from "./NavBar.module.css"
import Footer from "../Footer/Footer"
import { useLocation } from 'react-router-dom'

const NavBar = () => {

    const [isOpen, setOpen] = useState(false)
    const location = useLocation()

    return(
        <div style={{zIndex: 999}}>
            {!isOpen ?
                <nav className={styles.nav}>
                    <a href="/"><h1>MATTEO BONNET</h1></a>
                    <img src="/icons/menu.png" alt="Burger menu" onClick={() => setOpen(true)} />
                </nav>
            : <div className={styles.navOpened}>
                <img src="/images/grid.jpg" className={styles.bg} alt="Grid box" />
                <nav className={styles.nav}>
                    <a href="/"><h1>MATTEO BONNET</h1></a>
                    <img src="/icons/cross.png" alt="Burger menu" onClick={() => setOpen(false)} />
                </nav>
                <div>
                    <a href="/" style={location.pathname === "/" ? {color: "#F8BF92"} : undefined}>
                        <p>ABOUT ME</p>
                        <img src="/icons/link.png" alt="Link" />
                    </a>
                    <a href="/stacks" style={location.pathname === "/stacks" ? {color: "#F8BF92"} : undefined}>
                        <p>TECH STACKS</p>
                        <img src="/icons/link.png" alt="Link" />
                    </a>
                    <a href="/works" style={location.pathname === "/works" ? {color: "#F8BF92"} : undefined}>
                        <p>WORKS</p>
                        <img src="/icons/link.png" alt="Link" />
                    </a>
                    <a href="/projects" style={location.pathname === "/projects" ? {color: "#F8BF92"} : undefined}>
                        <p>PROJECTS</p>
                        <img src="/icons/link.png" alt="Link" />
                    </a>
                </div>
                <Footer />
            </div>}
        </div>
    )
}

export default NavBar