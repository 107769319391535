import styles from "./Navigation.module.css"

type Props = {
    previousText?: string,
    previousRoute?: string,
    nextText?: string,
    nextRoute?: string
}

const Navigation  = ({previousText, previousRoute, nextText, nextRoute}: Props) => {
    return(
        <div className={styles.nav}>
            {previousRoute && previousText ? <a href={previousRoute}>
                <img src="/icons/arrow.png" className={styles.leftArrow} alt="Arrow" />
                <p>{previousText}</p>
            </a> : <div></div>}
            {nextRoute && nextText ? <a href={nextRoute}>
                <p>{nextText}</p>
                <img src="/icons/arrow.png" className={styles.rightArrow} alt="Arrow" />
            </a> : <div></div>}
        </div>
    )
}

export default Navigation